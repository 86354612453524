@import '~antd/dist/antd.css';

.logo {
  height: 32px;
  margin: 16px;

}

.site-layout .site-layout-background {
  background: #fff;
}

.site-card-wrapper {
  padding: 30px;
  background: #ececec;
}